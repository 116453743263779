<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:title>
      <v-breadcrumbs :items="deep" class="pa-1">
        <template v-slot:divider>
          <v-icon>mdi-forward</v-icon>
        </template>
        <template v-slot:item="{ item }">
          <v-btn @click="onGoto(item)"> {{ item.text }} </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.refresh()"><btn-title icon="fas fa-sync-alt">Обновить</btn-title> </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn>
    </template>
    <portal to="v-main">
      <s-edit-form v-model="showEditDialog" v-if="showEditDialog" :api="url" :m="m" :id="idEdit" :configForm="{ type: 'default' }" :opt="{ closeAfterSave: false }" />
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :dataTable="dataTable"
      :model="m.list"
      :useQuery="false"
      :selectedRows="selectedRows"
      @click="
        counter += 1;
        onDetectClick($event);
      "
      @click1="onClickRow($event)"
      :defaults="{ paramName: `config`, params }"
    />
  </static-fullscreen-card>
</template>

<script>
import "./components";
import { getAccess, doubleClickDetect } from "@/components/mixings";
export default {
  mixins: [getAccess, doubleClickDetect],
  components: {},
  data() {
    return {
      deep: [{ id: 0, text: "/" }],
      idEdit: 0,
      showEditDialog: false,
      dataTable: [],
      title: "",
      m: {
        title: "",
        isDocument: false,
        api: "/config/edit",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 150 },
          { name: "key", title: "Ключ", type: "string", sortable: true, width: 450 },
          { name: "group", title: "Группа", type: "string", sortable: true, width: 450 },
          { name: "access_all", title: "Общий доступ", type: "switcher", validator1: ["req"], default: 0 },
          { name: "info", title: "Инфо", type: "string", sortable: true, width: 450 },
          { name: "data", title: "Конфиг", type: "text", sortable: true, width: 450 },
        ],
        form: [
          { name: "key", title: "Ключ", type: "string", validator: ["req"] },
          { name: "group", title: "Группа", type: "string" },
          { name: "data", title: "Конфиг", type: "dataObject", return: "object" },
          //{ name: "data", title: "Конфиг", type: "text" },
          { name: "info", title: "Инфо", type: "string" },
          { name: "access_all", title: "Общий доступ", type: "switcher", validator1: ["req"], default: 0 },
          { name: "accessRoles", title: "для роли..", type: "selectAccess2", accessType: "role", validator1: ["req"], default: [] },
          { name: "accessUsers", title: "для пользователя..", type: "selectAccess2", accessType: "user", validator1: ["req"], default: [] },
        ],
        config: {
          default: {
            fields: "key,group,data,info,access_all,accessRoles,accessUsers",
            fieldsForm: "key,group,access_all,accessRoles,accessUsers,info,data",
            fieldsRO: "",
          },
        },
      },
      url: "/config/edit",
      params: {},
      defaults: {
        paramName: "config",
      },
    };
  },
  created() {
    this.$root.title = "Конфигурация";
    //this.fetchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onGoto(e) {
      if (this.deep.length == 1) return;
      let idx = this.deep.findIndex((d) => d.id == e.id);
      if (idx < 0) return;
      let parent = this.deep[idx].id;
      let deep = [...this.deep];
      deep.splice(idx + 1);
      this.deep = deep;
      this.parent_id = parent;
    },
    async fetchData() {
      let data = [];
      this.loading = true;

      console.log("fetch");
      let params = {};
      //params= { filters: { key: { condition: 'SUBSTRING_INDEX(key, ".", 1)', value: "model" } } };
      let response = await this.$axios.get(this.url, { params });
      let obj = response.data.data || {};
      this.loading = !true;
      if (true) {
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            data.push({ id: key, name: key, sqlText: obj[key] });
          }
        }
      }
      let res = data.sort((a, b) => {
        return isNaN(a.id) ? (a.id > b.id) - (b.id > a.id) : parseFloat(a.id) > parseFloat(b.id);
      });
      this.dataTable = res;
    },
    onDoubleClick(d) {
      this.showEditDialogFun(d.row.id);
    },
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
